import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export class DefaultInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    hideAsterisk: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    autocomplete: PropTypes.string
  }

  onBlur = e => {
    if(this.props.onBlur !== undefined) {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      label,
      name,
      placeholder,
      type,
      value,
      error,
      disabled,
      min,
      max,
      required,
      onChange,
      autocomplete,
      hideAsterisk
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label} {required && !hideAsterisk ? <span className="required">*</span> : ''}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    return (
      <div className="defaultInput">
        {labelElement}
        <input
          type={type} 
          min={min} 
          max={max} 
          name={name} 
          className={classNames({ error: error })} 
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          autoComplete={autocomplete}
          onChange={onChange}
          onBlur={this.onBlur}
          required={required} />
        {errorElement}
      </div>
    )
  }
}

export default DefaultInput
