import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as cloneDeep from 'lodash/cloneDeep';

import DefaultSelect from 'components/inputs/DefaultSelect'

import {
  fetchSalesUsers
} from './duck'

class SalesUsers extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    showNoSalesPerson: PropTypes.bool,
    zeroValueText: PropTypes.string, 
    zeroValueItem: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    this.state = {
      noSalesPersonArr: null
    }
  }

  updateSalesUserArray = () => {
    if (this.props.items) {
      const noSalesPerson = { id: '-1', name: this.props.lng.customer.noSalesPerson };

      const copiedArr = cloneDeep(this.props.items)
      copiedArr.unshift(noSalesPerson)

      this.setState({
        noSalesPersonArr: copiedArr
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.items !== this.props.items || prevProps.showNoSalesPerson !== this.props.showNoSalesPerson) {
      this.updateSalesUserArray();
    }
  }

  componentDidMount() {
    if (!this.props.items) {
      this.props.dispatch(fetchSalesUsers())      
    }
    this.updateSalesUserArray();
  }

  render() {
    const {
      lng,
      loading,
      items,
      name,
      value,
      onChange,
      zeroValueItem,
      zeroValueText,
      showNoSalesPerson
      
    } = this.props

    const {
      noSalesPersonArr
    } = this.state

    return (
      <DefaultSelect
        zeroValueText={zeroValueText}
        name={name}
        onChange={onChange}
        value={value}
        items={showNoSalesPerson ? noSalesPersonArr : this.props.items}
        label={lng.customer.salesPerson}
        zeroValueItem={zeroValueItem}
        disabled={!items || loading}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.salesUsers.loading,
  items: state.salesUsers.items,
  lng: state.app.language
})

export default connect(mapStateToProps)(SalesUsers)