import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  TimesSolid
} from 'icons'

import './style.scss'

class FloatingWindow extends Component {
  static propTypes = {
    title: PropTypes.string,
    draggable: PropTypes.bool.isRequired,
    showHeader: PropTypes.bool.isRequired,
    closeOutside: PropTypes.bool,
    onClose: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.container = React.createRef();
    this.wrapper = React.createRef();
    this.state = {
      closeOutside: false,
      draggable: false,
      dragging: false,
      positionY: 0,
      positionX: 0,
      oldMouseX: 0,
      oldMouseY: 0
    }
  }

  onClose = (e) => {
    if (e.target && e.target.classList && e.target.classList.contains('floatingWindow'))
      this.props.onClose()
  }

  onDragStart = (e, data) => {
    e.stopPropagation()

    if (!this.state.draggable) {
      return false
    }

    this.setState({
      ...this.state,
      dragging: true,
      oldMouseX: e.clientX,
      oldMouseY: e.clientY
    })
  }

  onDrag = (e, data) => {
    if (this.state.dragging) {
      const changePosX = e.clientX - this.state.oldMouseX
      const changePosY = e.clientY - this.state.oldMouseY

      let newPosX = changePosX > 0 ? (this.state.positionX + changePosX) : (this.state.positionX - (changePosX * -1))
      let newPosY = changePosY > 0 ? (this.state.positionY + changePosY) : (this.state.positionY - (changePosY * -1))

      if (newPosX < 0) newPosX = 0
      if (newPosY < 0) newPosY = 0

      this.setState((state) => {
        return {
          ...state,
          positionY: newPosY,
          positionX: newPosX,
          oldMouseX: e.clientX,
          oldMouseY: e.clientY
        }
      })
    }
  }

  onDragEnd = (e, data) => {
    this.setState({
      ...this.state,
      dragging: false
    })

    return true
  }

  onResize = () => {
    if (!this.props.draggable) {
      this.setDefaultPosition()
    }
  }

  setDraggable = () => {
    if (!this.state.draggable && this.props.draggable) {
      this.setState({
        ...this.state,
        draggable: true
      })
    }
  }

  unsetDraggable = () => {
    if (!this.state.dragging) {
      this.setState({
        ...this.state,
        draggable: false
      })
    }
  }

  setDefaultPosition = () => {
    let initialY = (this.wrapper.current.clientHeight - this.container.current.clientHeight) / 2
    let initialX = (this.wrapper.current.clientWidth - this.container.current.clientWidth) / 2

    if (initialY < 0) initialY = 0
    if (initialX < 0) initialX = 0

    this.setState({
      positionY: initialY,
      positionX: initialX
    })
  }

  componentDidMount() {
    this.wrapper.current.addEventListener('mousemove', this.onDrag)
    this.wrapper.current.addEventListener('mouseup', this.onDragEnd)
    this.wrapper.current.addEventListener('mousedown', this.onDragStart)
    window.addEventListener('resize', this.onResize)

    this.setDefaultPosition()
  }

  componentWillUnmount() {
    this.wrapper.current.removeEventListener('mousemove', this.onDrag)
    this.wrapper.current.removeEventListener('mouseup', this.onDragEnd)
    this.wrapper.current.removeEventListener('mousedown', this.onDragStart)
    window.removeEventListener('resize', this.onResize)
  }

  render() {
    let closeButton = ''
    if (this.props.onClose !== undefined) {
      closeButton = <span className="headerCloseButton" onClick={this.props.onClose}><TimesSolid /></span>
    }
    const header = this.props.showHeader ? <div className="header"><span onMouseEnter={this.setDraggable} onMouseLeave={this.unsetDraggable}>{this.props.title}</span>{closeButton}</div> : null
    return (
      <div className="floatingWindow" ref={this.wrapper} onClick={this.props.closeOutside ? this.onClose : null}>
        <div
          ref={this.container}
          className="floatingContainer"
          style={{ top: this.state.positionY, left: this.state.positionX }}>
          {header}
          <div className="content">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default FloatingWindow