import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DefaultSelect from 'components/inputs/DefaultSelect'

import {
  fetchCustomerTypes
} from './duck'

class CustomerTypes extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    zeroValueItem: PropTypes.bool,
    zeroValueText: PropTypes.string
  }

  componentDidMount() {
    if (!this.props.items)
      this.props.dispatch(fetchCustomerTypes())
  }
  
  render() {
    const {
      lng,
      loading,
      items,
      name,
      value,
      onChange,
      zeroValueText,
      zeroValueItem
    } = this.props

    return (
      <DefaultSelect
        zeroValueText={zeroValueText}
        name={name}
        onChange={onChange}
        value={value}
        items={items}
        label={lng.common.customerType}
        zeroValueItem={zeroValueItem}
        disabled={!items || loading}
      />
    )
  }
}
const mapStateToProps = (state) => ({
  loading: state.customerTypes.loading,
  items: state.customerTypes.items,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerTypes)