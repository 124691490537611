import React, { Component } from 'react'
import { connect } from 'react-redux'
import RebateListItem from 'components/common/RebateListItem'
import Checkbox from 'components/inputs/Checkbox'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

import { fetchRebates, toggleCheckbox } from './duck.js'

export class CustomerRebates extends Component {
 
  onFilteredChange = () => {
    this.props.dispatch(toggleCheckbox())
  }

  componentDidMount() {
    const id = this.props.id;
    this.props.dispatch(fetchRebates(id))
  }

  render() {
    const {
      items,
      lng,  
      showAllRebates,
      loading
    } = this.props;

    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const showItems = showAllRebates ? items : items && items.filter(item => item.hasValues);
    
    return (
      
      <div className="customerRebates">
        <div className="header row">
          <div className="col-xs-4">{lng.common.name}</div>
          <div className="col-xs-2 right">{lng.common.discount}</div>
          <div className="col-xs-2 right">YTD {lastYear}</div>
          <div className="col-xs-2 right">YTD {currentYear}</div>
          <div className="col-xs-2 center">{lng.common.trend}</div>
        </div>
        <div className="items">
          {
            items ? showItems.map(item => {
              return (
                <RebateListItem
                  key={item.subGroupId}
                  name={item.subGroupName}
                  percent={item.discountPercent}
                  ytd={item.ytd}
                  ytdLastYear={item.ytdLastYear}
                  trend={item.salesTrendPercentage}
                />
              )
            }) : ''
          }
        </div>
        <Checkbox
          name="filteredRebates"
          label={lng.customer.buttons.showSubGroups}
          checked={showAllRebates}
          onChange={this.onFilteredChange} />
        {loading ? <LoadingOverlay /> : ''}        
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.customer.rebate.loading,
  items: state.customer.rebate.data,
  lng: state.app.language,
  showAllRebates: state.customer.rebate.showAllRebates
})

export default connect(mapStateToProps)(CustomerRebates)
