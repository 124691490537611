import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  fetchCustomerKeyMetrics
} from './duck'

import './style.scss'

export class CustomerKeyMetrics extends Component {

  calculateSoWPercentage = (partialValue, totalValue) => {
    return ((100 * partialValue) / totalValue).toFixed(2) + '%';
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.info && prevProps.info !== this.props.info) {
      this.props.dispatch(fetchCustomerKeyMetrics(this.props.info.id))
    }
  }

  render() {
    const {
      keyMetrics,
      lng,
      currencyCode,
    } = this.props;

    const divider = 1000000;

    return (
      <div className="customerKeyMetrics">
        <div className="header">{lng.customer.keyMetrics}</div>
        <div className="row">
          <div className="col-xs-6">{lng.customer.totalSales} (m{currencyCode})</div>
          <div className="col-xs-6">{keyMetrics && keyMetrics.totalSales ? (keyMetrics.totalSales / divider).toFixed(4) : '-'}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.customer.sales} (m{currencyCode})</div>
          <div className="col-xs-6">{keyMetrics && keyMetrics.grossSales ? (keyMetrics.grossSales / divider).toFixed(4) : '-'}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">SoW</div>
          <div className="col-xs-6">{keyMetrics && keyMetrics.grossSales && keyMetrics.grossPotential
            ? this.calculateSoWPercentage(keyMetrics.grossSales, keyMetrics.grossPotential) : '-'}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.customer.foreCast} (m{currencyCode})</div>
          <div className="col-xs-6">{keyMetrics && keyMetrics.grossPotential ? (keyMetrics.grossPotential / divider).toFixed(4) : '-'}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  info: state.customer.details.info,
  lng: state.app.language,
  currencyCode: state.app.currencyCode,
  keyMetrics: state.customerKeyMetrics.items
})

export default connect(mapStateToProps)(CustomerKeyMetrics)