
import { combineReducers } from 'redux'
import details from './CustomerDetails/duck'
import rebate from './CustomerRebates/duck'
import interactions from './CustomerInteraction/duck'
import statistics from './CustomerHistoricPurchases/duck'
import deliveryTimes from './CustomerDeliveryTimes/duck'
import customerEdit from './CustomerEdit/duck'

const reducer = combineReducers({
  details,
  rebate,
  interactions,
  statistics,
  deliveryTimes,
  customerEdit
})

export default reducer