import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageHeader from 'components/common/PageHeader'
import DefaultSelect from 'components/inputs/DefaultSelect'
import { setLanguageLocalStorage } from 'lib/utils.js'
import StyledButton from 'components/buttons/StyledButton'

import './style.scss'

import { fetchLanguage } from 'features/App/duck/index'

export class Settings extends Component {
  onChange = e => {
    const {
      value
    } = e.target;

    setLanguageLocalStorage(value);
    this.props.dispatch(fetchLanguage(value))    
  }

  onClickReturnToDashboard = () => {
    this.props.history.push('/')
  }

  render() {
    const {
      lng,
      currentLanguage
    } = this.props;

    const languages = [
      {key: 'sv', value: lng.common.swedish},
      {key: 'fi', value: lng.common.finnish}, 
      {key: 'lv', value: lng.common.latvian},
      {key: 'no', value: lng.common.norwegian},
      {key: 'en', value: lng.common.english}
    ]    
    
    return (
      <div className="settings">
        <PageHeader title={lng.common.settings} />
        <div className="languageSetting col-xs-12">
          <h3>{lng.common.language}</h3>
          <div className="col-xs-12 col-sm-2">
            <DefaultSelect
              name="language"
              items={languages}
              defaultValue={currentLanguage}
              onChange={this.onChange}
              zeroValueItem={false}
            />
          </div>
        </div>
        <div className="btn col-xs-4">
          <StyledButton text={lng.common.dashboard} onClick={this.onClickReturnToDashboard}></StyledButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  languages: state.app.language.common,
  currentLanguage: state.app.currentLanguage
})

export default connect(mapStateToProps)(Settings)