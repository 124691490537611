import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import LoadingCloud from 'components/common/LoadingCloud'

const PrivateRoute = ({ component: Component, authenticated: isAuthenticated, initialCheck: isChecked, ...rest }) => (

  isChecked ?
    <Route {...rest} render={props => (

      isAuthenticated ? (
        <Component {...props} />)
        :
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
    :
    <LoadingCloud text="Connecting..." />
)

export default PrivateRoute