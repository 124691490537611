const initialState = {
  currencyCode: null,
  language: null,
  defaultLanguage: null,
  currentLanguage: null,
  connectionError: false,
  connectionCheck: false,
  reconnectCount: 0
}

export default initialState;