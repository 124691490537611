import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export default class PageHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    feature: PropTypes.string
  }

  render() {
    const {
      title,
      feature
    } = this.props;

    return (
      <div className="pageHeader row">
        <div className={classNames('title', {'col-xs-8': feature, 'col-xs-12': !feature})}>{title}</div>
        {feature ? (<div className="feature col-xs-4">{feature}</div>) : ''}
      </div>
    )
  }
}
