const articleType = {
  other: 0,
  summerTyre: 1,
  winterTyre: 2,
  truckTyre: 3,
  loaderTyre: 4,
  mcTyre: 5,
  rim: 6,
  asseccories: 7
}

export default articleType;