import React, { useState, useEffect } from 'react'
import CustomerGroups from 'features/CustomerGroups'
import DefaultInput from 'components/inputs/DefaultInput'
import Button from 'components/buttons/Button'

import './style.scss'

const CustomerGroupsEdit = (props) => {
  const [state, setState] = useState({
    date: '',
    customerGroup: '',
    activeGroups: []
  })

  const onChange = (e) => {
    const {
      name,
      value
    } = e.target;

    setState({
      ...state,
      [name]: value
    })
  }

  const onClick = (e) => {
    e.preventDefault();

    const selectedGroup = props.customerGroups.find(g => g.id === Number(state.customerGroup));
    const hasGroup = state.activeGroups.find(g => g.key === Number(state.customerGroup))

    if (!selectedGroup || hasGroup || (selectedGroup.hasBonusGroups && state.date === ''))
      return;

    props.onAddGroup(selectedGroup.id, selectedGroup.name, state.date);

    setState({
      ...state,
      date: '',
      customerGroup: '',
      activeGroups:[
        ...state.activeGroups,
        {
          key: selectedGroup.id,
          value: selectedGroup.name
        }
      ]
    })
  }

  useEffect(() => {
    setState({
      activeGroups: [...props.activeCustomerGroups]
    })
  }, [props.activeCustomerGroups])

  const selectedGroup = props.customerGroups && props.customerGroups.find(g => g.id === Number(state.customerGroup));
  const showDateTime = selectedGroup && selectedGroup.hasBonusGroups;
  const disableButton = !selectedGroup || (showDateTime && state.date === '');

  return (
    <div className="customerGroupsEdit">
      <h4 className="groupHeader">{props.headerText}</h4>
      {
        state.activeGroups.map(group => (
          <div key={group.key}>- {group.value}</div>
        ))
      }
      <div className="row addGroup">
        <div className="col-xs-12 col-sm-8">
          <CustomerGroups
            name="customerGroup"
            value={state.customerGroup}
            onChange={onChange} />
          {showDateTime ?
            <DefaultInput
              type="date"
              name="startDate"
              label="Startdatum"
              value={state.date}
              onChange={onChange} /> : ''}
        </div>
      </div>
      <Button type="button" text={props.addText} disabled={disableButton} onClick={onClick} />
    </div>
  )
}

export default CustomerGroupsEdit;