import {
  CONNECTION_ISSUE
} from './commonTypes'

let timer = null;

const middleWare = ({
  dispatch
}) => next => action => {
  const {
    error,
    message
  } = action

  //Unauthorized error
  if (error && message === 'Failed to fetch') {
    if (timer != null)
      window.clearTimeout(timer);

    dispatch({
      type: CONNECTION_ISSUE
    })
  }

  return next(action)
}

export default middleWare;