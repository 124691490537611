import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export class CheckBoxSelect extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    zeroValueText: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      expanded: false,
      selectShow: '',
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        expanded: false
      })
    }
  }

  toggleShowCheckboxes = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  onChangeSelected = (e) => {
    const {
      value
    } = e.target;

    const {
      name
    } = this.props

    let values = [...this.props.values];
    const parsedValue = Number(value);

    if (values.indexOf(parsedValue) >= 0) {
      const index = values.indexOf(parsedValue)
      values.splice(index, 1)
      this.props.onChange(name, values)
    } else {
      values.push(parsedValue)
      this.props.onChange(name, values)
    }

    const toShow = [...this.props.items.filter(el => {
      return values.some(f => {
        return f === el.id
      })
    })]

    this.setState({
      selectShow: toShow
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const {
      items,
      name,
      label,
      values,
      error,
      zeroValueText
    } = this.props

    const {
      expanded,
      selectShow
    } = this.state

    const labelElement = label ? (
      <label htmlFor={name}>{label}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    return (
      <div ref={this.wrapperRef} className="checkBoxSelect">
        {labelElement}
        <div className="selectBox" onClick={this.toggleShowCheckboxes}>
          <select
            onBlur={this.onBlur}
            className={classNames({ error: error })}
          />
          <div className="overSelect row">
            {
              values.length && selectShow ? selectShow.map(i => i.name).join(', ') : zeroValueText
            }
          </div>
        </div>
        {
          expanded ?
            <div className="boxes">
              {
                items ? items.map(item =>
                  <div
                    key={item.id}
                    className="selectOpt row">
                    <input autoComplete="off"
                      className="checkboxes"
                      type="checkbox"
                      id={item.id}
                      name={name}
                      value={item.id}
                      onChange={this.onChangeSelected}
                      checked={this.props.values.indexOf(item.id) >= 0} />
                    <label htmlFor={item.id} className="checkText">
                      {item.name}
                    </label>
                  </div>) : null
              }
            </div> : null
        }
        { errorElement}
      </div>
    )
  }
}

export default CheckBoxSelect