import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'lib/utils'

export default class AutoSizer extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      height: 100,
      calculated: false
    }
  }

  calculateListHeight = (force = false) => {
    if (!this.listRef || !this.listRef.current)
      return;

    const offsetTop = this.listRef.current.offsetTop;
    const height = window.innerHeight - offsetTop - 8;

    if ((!this.state.calculated || force) && height > 100) {
      this.setState({
        height: height,
        calculated: true
      })
    }
  }

  forceCalculation = () => this.calculateListHeight(true)

  componentDidMount() {
    window.addEventListener('resize', throttle(this.forceCalculation, 250))
    this.calculateListHeight();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', throttle(this.forceCalculation, 250))
  }

  componentDidUpdate(prevProps, prevState) {
    this.calculateListHeight();
  }

  render() {
    return (
      <div ref={this.listRef}>
        {this.props.children(this.state.height)}
      </div>
    )
  }
}
