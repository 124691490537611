import React, { PureComponent } from 'react'

import './style.scss'

export default class ErrorMessage extends PureComponent {
  render() {
    return (
      <div className="errorMessage">
        {this.props.message ? this.props.message : 'An error has occurred'}
      </div>
    )
  }
}
