export const format = (value, n, x, s, c) => {
  n = (n === undefined ? 2 : n)
  x = (x === undefined ? 3 : x)
  s = (s === undefined ? ' ' : s)
  c = (c === undefined ? ',' : c)

  var re = '\\d(?=(\\d{' + x + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = value.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s)
}

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function () {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const dynamicSort = (property) => {
  if (property.indexOf(',') > -1)
    return dynamicSortMultiple(property.split(','));

  if (property.indexOf('.') > -1)
    return dynamicSortNested(property.split('.'));

  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export const dynamicSortNested = (properties) => {

  var sortOrder = 1;
  if (properties[0].indexOf('-') === 0) {
    sortOrder = -1;
    properties[0] = properties[0].substr(1);
  }

  return function (a, b) {
    var aTemp = a;
    var bTemp = b;
    for (var i = 0; i < properties.length; i++) {
      aTemp = aTemp[properties[i]]
      bTemp = bTemp[properties[i]]
    }

    var result = (aTemp < bTemp) ? -1 : (aTemp > bTemp) ? 1 : 0;
    return result * sortOrder;
  }
}

export const dynamicSortMultiple = (...theArguments) => {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = theArguments;

  // To support calling with splited string
  if (Object.prototype.toString.call(props[0]) === '[object Array]')
    props = props[0];

  return function (obj1, obj2) {
    var i = 0, result = 0, numberOfProperties = props.length;
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  }
}

export const getDomainName = (hostName) => {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
}

export const setLanguageLocalStorage = (languageCode) => {
  localStorage.setItem('language', languageCode)
}

export const getLanguageLocalStorage = () => {
  return localStorage.getItem('language')
}