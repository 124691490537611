import React, { Component } from 'react'
import { connect } from 'react-redux'

import { format } from 'lib/utils.js'
import articleType from 'enums/articleType'
import LoadingOverlay from 'components/common/LoadingOverlay'

import {
  fetchStatistics
} from './duck'

import './style.scss'

export class CustomerHistoricPurchases extends Component {
  divider = 1000;

  getArticleTypeName = typeId => {
    switch(typeId) {
      case articleType.summerTyre:
        return this.props.lng.articleTypes.summerTyre;
      case articleType.winterTyre:
        return this.props.lng.articleTypes.winterTyre;
      case articleType.rim:
        return this.props.lng.articleTypes.rim;
      case articleType.truckTyre:
        return this.props.lng.articleTypes.truckTyre;
      case articleType.loaderTyre:
        return this.props.lng.articleTypes.loaderTyre;
      case articleType.asseccories:
        return this.props.lng.articleTypes.accessories;
      case articleType.mcTyre:
        return this.props.lng.articleTypes.mcTyre;
      default:
        return this.props.lng.articleTypes.other;
    }
  }

  componentDidMount() {
    const id = this.props.id;
    this.props.dispatch(fetchStatistics(id))
  }

  render() {

    const {
      items,
      loading,
      lng,
      currencyCode,
    } = this.props

    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    return (
      <div className="customerHistoricPurchases">
        {loading ? <LoadingOverlay /> : ''}
        <div className="header row">
          <div className="col-xxs-8 col-xs-4">{lng.customer.historyPurchases}</div>
          <div className="xxs-hide col-xs-2 right">{lastYear - 1}</div>
          <div className="xxs-hide col-xs-2 right">{lastYear}</div>
          <div className="col-xs-2 right">YTD {lastYear}</div>
          <div className="col-xs-2 right">YTD {currentYear}</div>
        </div>
        <div className="groupHeader">{lng.customer.sales} (K{currencyCode})</div>
        {
          items ? items.map(item => (
            <div className="itemRow row even" key={item.type}>
              <div className="col-xxs-8 col-xs-4">{this.getArticleTypeName(item.type)}</div>
              <div className="xxs-hide col-xs-2 right">{format(item.salesBeforeLastFullYear / this.divider)}</div>
              <div className="xxs-hide col-xs-2 right">{format(item.salesLastFullYear / this.divider)}</div>
              <div className="col-xs-2 right">{format(item.salesYTDLastYear / this.divider)}</div>
              <div className="col-xs-2 right">{format(item.salesYTD / this.divider)}</div>
            </div>
          )) : ''
        }
        {
          items && !items.length ? <div className="itemRow row noRows">{lng.common.noDataFound}</div> : ''
        }
        <div className="groupHeader">{lng.customer.volume} (#)</div>
        {
          !loading && items ? items.map(item => (
            <div className="itemRow row even" key={item.type}>
              <div className="col-xxs-8 col-xs-4">{this.getArticleTypeName(item.type)}</div>
              <div className="xxs-hide col-xs-2 right">{item.volumeBeforeLastFullYear}</div>
              <div className="xxs-hide col-xs-2 right">{item.volumeLastFullYear}</div>
              <div className="col-xs-2 right">{item.volumeYTDLastYear}</div>
              <div className="col-xs-2 right">{item.volumeYTD}</div>
            </div>
          )) : ''
        }
        {
          items && !items.length ? <div className="itemRow row noRows">{lng.common.noDataFound}</div> : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.customer.statistics.loading,
  items: state.customer.statistics.items,
  lng: state.app.language,
  currencyCode: state.app.currencyCode,
})

export default connect(mapStateToProps)(CustomerHistoricPurchases)