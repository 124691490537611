const initialState = {
  loading: false,
  addInteraction: false,
  items: null,
  interactionFields: {
    perticipants: '',
    rationale: '',
    notes: ''
  },
  interactionAdded: false,
}

const TOGGLE_INTERACTION = 'features/Customer/CustomerInteraction/TOGGLE_INTERACTION';

const INTERACTION_CHANGE = 'features/Customer/CustomerInteraction/INTERACTION_CHANGE';

const FETCH_CUSTOMERINTERACTION = 'features/Customer/CustomerInteraction/FETCH_CUSTOMERINTERACTION';
const FETCH_CUSTOMERINTERACTION_SUCCESS = 'features/Customer/CustomerInteraction/FETCH_CUSTOMERINTERACTION_SUCCESS';
const FETCH_CUSTOMERINTERACTION_FAILED = 'features/Customer/CustomerInteraction/FETCH_CUSTOMERINTERACTION_FAILED';

const INSERT_CUSTOMERINTERACTION = 'features/Customer/CustomerDetails/INSERT_CUSTOMERINTERACTION';
const INSERT_CUSTOMERINTERACTION_SUCCESS = 'features/Customer/CustomerDetails/INSERT_CUSTOMERINTERACTION_SUCCESS';
const INSERT_CUSTOMERINTERACTION_FAILED = 'features/Customer/CustomerDetails/INSERT_CUSTOMERINTERACTION_FAILED';

export const toggleInteraction = () => {
  return {
    type: TOGGLE_INTERACTION
  }
}

export const fetchCustomerInteractions = id => {
  return {
    types: [FETCH_CUSTOMERINTERACTION, FETCH_CUSTOMERINTERACTION_SUCCESS, FETCH_CUSTOMERINTERACTION_FAILED],
    callApi: {
      endpoint: `api/CustomerInteraction/${id}`,
    }
  }
}

export const insertCustomerInteraction = (id,criteria) => {
  return {
    types: [INSERT_CUSTOMERINTERACTION, INSERT_CUSTOMERINTERACTION_SUCCESS, INSERT_CUSTOMERINTERACTION_FAILED],
    callApi: {
      endpoint: `api/CustomerInteraction/${id}`,
      method: 'POST',
      data: criteria
    }
  }
}

export const interactionChange = (name, value) => {
  return {
    type: INTERACTION_CHANGE,
    name,
    value
  }
}


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case TOGGLE_INTERACTION:
      return {
        ...state,
        addInteraction: !state.addInteraction,
        interactionFields: {
          perticipants: '',
          rationale: '',
          notes: ''
        },
      }

    case FETCH_CUSTOMERINTERACTION:
      return {
        ...state,
        loading: true,
        items: null,
        error: false,
        interactionAdded: false,
      }

    case FETCH_CUSTOMERINTERACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_CUSTOMERINTERACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        interactionAdded: false,
      }

    case INSERT_CUSTOMERINTERACTION:
      return {
        ...state,
        loading: true,
        items: null,
        error: false,
        interactionAdded: false,
      }

    case INSERT_CUSTOMERINTERACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        interactionAdded: true,
      }

    case INSERT_CUSTOMERINTERACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        interactionAdded: false,
      }

    case INTERACTION_CHANGE:
      return {
        ...state,
        interactionFields: {
          ...state.interactionFields,
          [action.name]: action.value
        }
      }

    default:
      return state
  }
}
