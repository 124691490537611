import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

export default class Checkbox extends PureComponent {

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    hideAsterisk: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool.isRequired
  }

  render() {
    const {
      label,
      name,
      required,
      hideAsterisk,
      onChange,
      checked
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label} {required && !hideAsterisk ? <span className="required">*</span> : ''}</label>
    ) : null

    return (
      <div className="checkbox">
        <input type="checkbox"
          id={name}
          name={name}
          label={label}
          onChange={onChange}
          checked={checked} />
        {labelElement}
      </div>
    )
  }
}
