import React, { Component } from 'react'

import './style.scss'

export default class ConnectionError extends Component {
  render() {
    return (
      <div className="connectionError">
        Connection issue! Reconnecting...
      </div>
    )
  }
}
