const initialState = {
  loading: false,
  items: null,
  error: false
}

const actionPath = 'features/Customer/CustomerHistoricPurchases/'

const FETCH_STATISTICS = actionPath + 'FETCH_STATISTICS'
const FETCH_STATISTICS_SUCCESS = actionPath + 'FETCH_STATISTICS_SUCCESS'
const FETCH_STATISTICS_FAILED = actionPath + 'FETCH_STATISTICS_FAILED'

export const fetchStatistics = id => {
  return {
    types: [FETCH_STATISTICS, FETCH_STATISTICS_SUCCESS, FETCH_STATISTICS_FAILED],
    callApi: {
      endpoint: `api/CustomerStatistics/${id}`
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case FETCH_STATISTICS:
      return {
        ...state,
        loading: true,
        items: null
      }

    case FETCH_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_STATISTICS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}

