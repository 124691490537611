import React, { Component } from 'react'
import Button from 'components/buttons/Button'
import RadioButton from 'components/inputs/RadioButton'
import PropTypes from 'prop-types'

export class ArticleListPrinting extends Component {
  static propTypes = {
    lng: PropTypes.object.isRequired,
    customerInfo: PropTypes.object,
    languageCode: PropTypes.string,
    toggleArticleType: PropTypes.func,
    articleType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired
  }

  onClick = () => {
    window.open(`/api/Excel/?customerId=${this.props.customerId}&articleType=${this.props.articleType}`);
  }

  render() {

    const {
      lng,
      articleType
    } = this.props;

    return (
      <div className="articleListPrinting column">
        <div className="row col-xs-12">
          <RadioButton
            className="radioBtn"
            label={lng.articleTypes.summerTyre}
            name="articleType"
            value="1"
            onChange={this.props.onChange}
            checked={articleType === '1'} />
          <RadioButton
            className="radioBtn"
            name="articleType"
            value="2"
            label={lng.articleTypes.winterTyre}
            onChange={this.props.onChange}
            checked={articleType === '2'} />
        </div>
        <div className="formButtons row center-xs">
          <div className="col-xs-6">
            <Button type="button" text={lng.printing.print} onClick={this.onClick} />
          </div>
          <div className="col-xs-6">
            <Button type="submit" text={lng.printing.buttons.sendEmail} />
          </div>
        </div>
      </div>
    )
  }
}

export default ArticleListPrinting;