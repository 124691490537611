import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  loadDeliveryTimes
} from './duck'
import './style.scss'

export class CustomerDeliveryTimes extends PureComponent {
  componentDidMount() {
    this.props.dispatch(loadDeliveryTimes(this.props.customerId))
  }

  render() {
    const {
      loading,
      items,
      lng
    } = this.props

    return (
      <div className="customerDeliveryTimes">
        <div className="header">{lng.common.deliveryTimes}</div>
        {
          items ? items.map(item => {
            return (
              <div className="row" key={item.wayOfDeliveryName}>
                <div className="col-xs-6">{item.wayOfDeliveryName}</div>
                <div className="col-xs-6">{item.deliveryDays} ({item.deliveryCount} {lng.customer.shipments})</div>
              </div>
            )
          }) : ''
        }
        {
          !loading && items && !items.length ? <div className="itemRow row noRows">{lng.common.noDataFound}</div> : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.customer.deliveryTimes.items,
  loading: state.customer.deliveryTimes.loading,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerDeliveryTimes)