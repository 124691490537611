import React from 'react'
import LoadingSpinner from 'components/common/LoadingSpinner'
import './style.css'

const LoadingOverlay = () => (
  <div className="loadingOverlay">
    <div className="loading">
      <LoadingSpinner />
    </div>
  </div>
)

export default LoadingOverlay