const initialState = {
  loading: false,
  items: null
}

const actionPath = 'features/CustomerTypes/'
const FETCH_CUSTOMER_TYPES = actionPath + 'FETCH_CUSTOMER_TYPES'
const FETCH_CUSTOMER_TYPES_SUCCESS = actionPath + 'FETCH_CUSTOMER_TYPES_SUCCESS'
const FETCH_CUSTOMER_TYPES_FAILED = actionPath + 'FETCH_CUSTOMER_TYPES_FAILED'

export const fetchCustomerTypes = () => {
  return {
    types: [FETCH_CUSTOMER_TYPES, FETCH_CUSTOMER_TYPES_SUCCESS, FETCH_CUSTOMER_TYPES_FAILED],
    callApi: {
      endpoint: 'api/customerTypes'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_TYPES:
      return {
        ...state,
        loading: true
      }

    case FETCH_CUSTOMER_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_CUSTOMER_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }

    default:
      return state
  }
}