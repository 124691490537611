const initialState = {
  saving: false,
  error: false,
  editCustomer: false,
}

const actionPath = 'components/editors/CustomerEdit/';

const UPDATE_CUSTOMER = actionPath + 'UPDATE_CUSTOMER';
const UPDATE_CUSTOMER_SUCCESS = actionPath + 'UPDATE_CUSTOMER_SUCCESS';
const UPDATE_CUSTOMER_FAILED = actionPath + 'UPDATE_CUSTOMER_FAILED';

const TOGGLE_EDIT = actionPath + 'TOGGLE_EDIT';

export const toggleEdit = () => {
  return {
    type: TOGGLE_EDIT
  }
}

export const updateCustomer = (customerId, values) => {
  return {
    types: [UPDATE_CUSTOMER, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILED],
    callApi: {
      endpoint: `api/Customer/${customerId}`,
      method: 'PUT',
      data: values
    },
    customerInfo: values
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case TOGGLE_EDIT:
      return {
        ...state,
        editCustomer: !state.editCustomer,
        error: false
      }

    case UPDATE_CUSTOMER:
      return {
        ...state,
        saving: true,
        error: false
      }

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        saving: false,
        editCustomer: false    
      }

    case UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        saving: false,
        error: true
      }

    default:
      return state
  }
}