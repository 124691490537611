import React, { Component } from 'react'

import FeatureButton from 'components/buttons/FeatureButton'
import AddressCardSolid from 'icons/AddressCardSolid'
import ExclamationCircleSolid from 'icons/ExclamationCircleSolid'

import './style.scss';
import { connect } from 'react-redux';

class Start extends Component {
  onClickFeature = (link) => {
    this.props.history.push(link);
  }

  render() {
    const {
      lng
    } = this.props;

    return (
      <div className="start">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <FeatureButton
              onClick={this.onClickFeature}
              text={lng.common.settings}
              link="settings">
              <ExclamationCircleSolid />
            </FeatureButton>
          </div>
          {/* <div className="col-xs-12 col-sm-6 col-md-3">
            <FeatureButton
              onClick={this.onClickFeature}
              text="Säljstrategi"
              link="salesstrategy">
              <ExclamationCircleSolid />
            </FeatureButton>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <FeatureButton
              onClick={this.onClickFeature}
              text="Säljledning"
              link="salesmanagement">
              <ExclamationCircleSolid />
            </FeatureButton>
          </div> */}
          <div className="col-xs-12 col-sm-6 col-md-3">
            <FeatureButton
              onClick={this.onClickFeature}
              text={lng.common.customerCard}
              link="salesefficiency">
              <AddressCardSolid />
            </FeatureButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.app.language
});

export default connect(mapStateToProps)(Start)
