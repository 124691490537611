import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { format } from 'lib/utils.js'

import {
  ArrowDownSolid,
  ArrowUpSolid
} from 'icons'

import './style.scss'

export default class RebateListItem extends Component {  
  static propTypes = {
    name: PropTypes.string.isRequired,
    percent: PropTypes.number,
    fullYear: PropTypes.number,
    ytd: PropTypes.number,
    ytdLastYear: PropTypes.number,
    trend: PropTypes.number,
  }

  divider = 1000;

  render() {
    const {
      name,
      percent,
      ytd,
      ytdLastYear,
      trend
    } = this.props;

    let arrow;

    if(!trend)
      arrow = '-'
    else if(trend < 0)
      arrow = <ArrowDownSolid className="down"/>
    else
      arrow = <ArrowUpSolid className="up"/>

    return (
      <div className="row rebateListItem even">
        <div className="col-xs-4">{name}</div>
        <div className="col-xs-2 right">{Math.round(percent * 100)} %</div>
        <div className="col-xs-2 right">{ytdLastYear ? format(ytdLastYear / this.divider) : '-'}</div>
        <div className="col-xs-2 right">{ytd ? format(ytd / this.divider) : '-'}</div>
        <div className="col-xs-2 center">{arrow}</div>
      </div>
    );
  }
}
