const rules = {
  perticipants: {
    required: true,
    minLength: 3
  },
  rationale: {
    required: true,
    minLength: 3    
  },
  notes:{
    required: true,
    minLength: 3,
    maxLength: 255
  }
}
export default rules;