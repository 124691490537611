import { useSwipeable } from 'react-swipeable'
import PropTypes from 'prop-types'

const Swiper = ({ children, delta, ...props }) => {
  const onSwiped = event => {
    if (props.onSwiped)
      props.onSwiped(event)
  }

  const onSwipedLeft = event => {
    if (props.onSwipedLeft)
      props.onSwipedLeft(event)
  }

  const onSwipedRight = event => {
    if (props.onSwipedRight)
      props.onSwipedRight(event)
  }

  const handlers = useSwipeable({
    onSwiped: event => onSwiped(event),
    onSwipedLeft: event => onSwipedLeft(event),
    onSwipedRight: event => onSwipedRight(event),
    delta: delta || 10
  });

  return (
    <div {...handlers}>{children}</div>
  )
}

Swiper.propTypes = {
  onSwiped: PropTypes.func,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  delta: PropTypes.number
}

export default Swiper