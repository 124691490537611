import React, { Component } from 'react'

export class NotFoundPage extends Component {
  render() {
    return (
      <div className="notFound">
        Sidan du försöker hitta finns inte...
      </div>
    )
  }
}

export default NotFoundPage