const rules = {
  emailAddress: {
    multipleEmails: true,
    required: true
  },
  emailAddressWayBills: {
    multipleEmails: true
  },
  emailAddressInvoices: {
    multipleEmails: true
  },
  grossPotential: {
    number: true,
    maxLength: 10
  },
  totalSales: {
    number: true,
    maxLength: 10
  }
}

export default rules;