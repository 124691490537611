import { combineReducers } from 'redux'
import app from 'features/App/duck'
import login from 'features/Login/duck'
import customers from 'features/Customers/duck'
import customer from 'features/Customer/duck'
import customerGroups from 'features/CustomerGroups/duck'
import salesUsers from 'features/SalesUsers/duck'
import customerTypes from 'features/CustomerTypes/duck'
import printingWindow from 'features/PrintingWindow/duck'
import customerKeyMetrics from 'features/Customer/CustomerKeyMetrics/duck'

const rootReducer = combineReducers({
  app,
  login,
  customers,
  customer,
  customerGroups,
  salesUsers,
  customerTypes,
  printingWindow,
  customerKeyMetrics
})

export default rootReducer