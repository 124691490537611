import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export default class GridHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }

  onClick = () => {
    this.props.onClick(this.props.name);
  }

  render() {
    const {
      title,
      className
    } = this.props;

    return (
      <div className={classNames('gridHeader', className)} onClick={this.onClick}>
        {title}
      </div>
    )
  }
}
