import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DefaultSelect from 'components/inputs/DefaultSelect'

import {
  fetchGroups
} from './duck'

class CustomerGroups extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any
  }

  componentDidMount() {
    if (!this.props.items)
      this.props.dispatch(fetchGroups())
  }

  render() {
    const {
      lng,
      loading,
      items,
      name,
      value,
      onChange
    } = this.props

    return (
      <DefaultSelect
        zeroValueText={lng.common.choose}
        name={name}
        onChange={onChange}
        value={value}
        items={items}
        label={lng.customer.customerGroup}
        zeroValueItem={true}
        disabled={!items || loading}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.customerGroups.loading,
  items: state.customerGroups.items,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerGroups)
