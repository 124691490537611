import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export default class ImageButton extends Component {
  static propTypes = {
    src: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    border: PropTypes.bool,
    className: PropTypes.string
  }

  onClick = () => {
    const name = this.props.name;
    this.props.onClick(name);
  }

  render() {
    const {
      src,
      text,
      children,
      active,
      border,
      className
    } = this.props;

    return (
      <div className={classNames('imageButton', className, {active: active, border: border})} onClick={this.onClick}>
        {text ? <div className="text">{text}</div> : ''}
        <div>
          {children ? children : <img src={src} alt="text" />}
        </div>
      </div>
    )
  }
}
