import React, { Component } from 'react'
import { connect } from 'react-redux'

import ImageButton from 'components/buttons/ImageButton'
import InteractionWindow from './InteractionWindow'
import { PlusSquareSolid } from 'icons'
import dayjs from 'dayjs'

import {
  toggleInteraction,
  fetchCustomerInteractions,
} from './duck'

import './style.scss'

export class CustomerInteraction extends Component {


  componentDidMount() {
    const id = this.props.id;
    this.props.dispatch(fetchCustomerInteractions(id));
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.interactionAdded){
      this.props.dispatch(fetchCustomerInteractions(this.props.id));
    }
  }

  onClickAdd = () => {
    this.props.dispatch(toggleInteraction());
  }

  render() {
    const {
      addInteraction,
      items,
      lng
    } = this.props;

    const translation = lng.customer;

    return (
      <div className="customerInteraction">
        <div className="header row">
          <div className="col-xs-8">{lng.common.notes}</div>
          <div className="col-xs-4 row end-xs">
            <ImageButton onClick={this.onClickAdd} className="blue smal">
              <PlusSquareSolid />
            </ImageButton>
          </div>
        </div>
        <div className="groupHeader row">
          <div className="col-xxs-4 col-xs-3">{lng.common.date}</div>
          <div className="xxs-hide col-xs-3">{translation.perticipants}</div>
          <div className="xxs-hide col-xs-3">{translation.rationale}</div>
          <div className="col-xxs-8 col-xs-3">{lng.common.notes}</div>
        </div>

        {
          items ? items.map(item => {
            return (
              <div className="itemRow row even" key={item.id} id={item.id} >
                <div className="col-xxs-4 col-xs-3">{dayjs(item.interactionDate).format('YYYY-MM-DD HH:mm')}</div>
                <div className="xxs-hide col-xs-3">{item.perticipants}</div>
                <div className="xxs-hide col-xs-3">{item.rationale}</div>
                <div className="col-xxs-8 col-xs-3">{item.notes}</div>
              </div> 
            )
          }) : ''
        }
        {
          items && !items.length ? <div className="itemRow row noRows">{lng.common.noDataFound}</div> : ''
        }

        {addInteraction ? <InteractionWindow /> : ''}     
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  addInteraction: state.customer.interactions.addInteraction,
  items: state.customer.interactions.items,
  interactionAdded: state.customer.interactions.interactionAdded,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerInteraction)
